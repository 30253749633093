import React from 'react';
import withUsabilityTestingTemplate from 'hoc/content-cluster';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Link from 'components/ui/link';
import { transformImages } from 'helpers/transformImages';
import { useSourceParams } from 'hooks/useSourceParams';

const UXResearch = () => {
  const paragraph = useSourceParams('stage3');
  const {
    allFile: { edges },
  } = useStaticQuery(graphql`
    query UXResearch {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "user-experience/ux-research" }
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(quality: 95) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);
  const images = transformImages(edges);
  return (
    <>
      <h2>Why Does UX Research Matter?</h2>
      <p>
        Before venturing into UX prototyping, it is worth explaining what a prototype is and what
        business benefits it brings. A prototype is an elementary design that has the potential to
        turn into the final product. It is a basic version of a product that enables you to conduct
        initial product testing and collect feedback from potential users.
      </p>
      <p>
        Designing a product is a challenging endeavor. However, user experience research makes it
        easier by providing the necessary data required at each step of product design. It allows
        you to validate your hypothesis and analyze your product against a backdrop of similar
        solutions on the market. It also guides you in understanding the end user&apos;s
        expectations, goals, and challenges.{' '}
      </p>
      <p>
        As aptly noticed by{' '}
        <Link
          href="https://www.toptal.com/designers/user-research/guide-to-ux-research-methods"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Toptal
        </Link>
        , “user experience research is one of the most misunderstood yet critical steps in UX
        design. Sometimes treated as an afterthought or an unaffordable luxury, UX research, and
        user testing should inform every design decision”.
      </p>
      <p>
        Now that we know the gist of it, let us explore the different types of UX research in the
        next section.{' '}
      </p>
      <h2>Types of UX Research</h2>
      <p>
        UX research can be roughly divided into two types – qualitative and quantitative. These
        research types gather data in a contrasting way. While qualitative studies are based on
        direct observations, quantitative studies gather numerical data – for instance, through
        online surveys.
      </p>

      <p>
        Once combined, these two types can provide a great depiction of your customer&apos;s wants
        and needs.
      </p>
      <h3>Quantitative research</h3>
      <p>
        This type of research quantifies or gathers measurable data. It offers precise and fixed
        figures and numbers to work with. As an example, it can show you how many users purchased
        your product via the e-commerce app or the percentage of visitors that added an item to
        their wish list. This method lets you put a number or statistic on the usability of your
        product. Also, design comparisons can be made to determine which version outsmarts the
        others.
      </p>
      <h3>Qualitative research</h3>

      <p>
        Qualitative research discovers the reasons behind quantitative actions taken by end-users.
        As an example, scenarios like why a user added a certain item to a wish list instead of
        purchasing it, or why they bounced from your website can be explored via employing this
        method. While quantitative data is fixed in nature, qualitative data is more open-ended and
        descriptive.
      </p>
      <p>
        It’s important to note that user experience research should be conducted at every stage of
        your product design and development. Fortunately, different research methods can aid in this
        process. Depending on the stage of development – some methods might take precedence over
        others.
      </p>
      <h2>UX Research Methods – When to Choose Which</h2>
      <p>
        According to the Nielsen Norman Group, UX research methods can be divided roughly into four{' '}
        <Link
          href="https://www.nngroup.com/articles/ux-research-cheat-sheet/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          stages
        </Link>
        . These are: Discover, Explore, Learn, and Listen.
      </p>
      <p>Let us look at each method in detail.</p>
      <Img
        fluid={images.uxactivities.node.childImageSharp.fluid}
        alt="UX activities in the product & service design cycle"
        title="Chose your reasearch method"
      />
      <h3>Stage 1 – Discover</h3>
      <p>In the ‘Discover’ stage, methods include:</p>
      <ul>
        <li>
          <strong>Field studies</strong>: This method includes conducting studies about the user’s
          natural environment as opposed to a restricted environment like a lab or office.{' '}
        </li>
        <li>
          <strong>Stakeholder interviews</strong>: UX research at the discovery stage should involve
          the stakeholder interviews, as they can provide detailed and valuable product information
          and steer your product to success. Defining your goal and understanding the parameters,
          user insights, and assumptions set forth by the stakeholders is a crucial step.
        </li>
        <li>
          <strong>User interviews</strong>: It is pertinent to interview the potential end-users of
          your product or design to get into the mind of the users and see how they would benefit
          from your product. These interviews are normally conducted during the ideation phase or
          early concept development. The interview questions are delivered via a structured
          methodology covering a variety of topics. The interview is generally recorded and
          systematically analyzed after the conversation is over to extract the main points.{' '}
        </li>
        <li>
          <strong>Competitive analysis</strong>: This is a market analysis to compare and gather
          data about products and companies. To ensure that your product fills in a genuine niche
          and will sell, it is very important to see what your competitors are up to. This method
          will bring to light any strengths or weaknesses of your intended products so you can make
          smart decisions regarding your development strategy.{' '}
        </li>
      </ul>
      <p>
        <strong>When are these methods used?</strong> These methods are applied when you wish to
        discover user needs, expectations, and problems. They are used when you are considering
        developing a product and need to validate your idea.
      </p>
      <h3>Stage 2 – Explore </h3>
      <p>The methods employed in the ‘Explore’ stage include:</p>
      <ul>
        <li>
          <strong>User persona development</strong>: A user persona is like a fictional
          representation of your customer. The purpose of{' '}
          <Link
            href="https://www.interaction-design.org/literature/article/personas-why-and-how-you-should-use-them"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            developing personas
          </Link>{' '}
          is to create dependable and realistic representations of your key audience segments for
          reference. Creating a user persona will build empathy with your target users and identify
          exactly what they need and why.{' '}
        </li>
        <li>
          <strong>Task assignments</strong>: These are the actions that you ask the participants to
          take during your research study. Tasks provide context to users so they can engage with
          the product as they would in real life.{' '}
        </li>
        <li>
          <p>
            <strong>Card sorting</strong>: This is a UX research method in which participants
            organize topics into categories in a way that makes sense to them.
            <Link
              href="https://www.usability.gov/how-to-and-tools/methods/card-sorting.html"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              {' '}
              Card sorting
            </Link>{' '}
            can be done by using actual cards, pieces of papers, or any type of online card-sorting
            tool.{' '}
          </p>
          <Img
            fluid={images.analyzing.node.childImageSharp.fluid}
            alt="Card sorting"
            title="One of reaserch method - card sorting"
          />
          <p>
            Source:{' '}
            <Link
              href="https://unsplash.com/photos/ywwuOBJy60c"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              Unsplash
            </Link>
          </p>
        </li>
        <li>
          <strong>User stories</strong>: User stories are scenarios that enable a product designer
          to empathize with the end-user and generate ideas to better their product that fits well
          into the user’s life. These stories are full of minor details like activities and thoughts
          and can be presented via different media.
        </li>
      </ul>
      <p>
        <strong>When are these methods used?</strong> The above methods are used for exploring and
        validating potential user paths, designs, and product features before you invest your time
        and resources into developing the product.
      </p>
      <h3>Stage 3 – Learn</h3>
      <p>In the ‘Learn’ stage, UX research methods include:</p>
      <ul>
        <li>
          <strong>Usability testing</strong>: One of the easiest ways to start with{' '}
          <Link href="/usability-testing/" target="_blank" rel="noopener noreferrer">
            usability testing
          </Link>{' '}
          is to conduct session recordings. Session recordings allow you to replay and observe real
          actions performed by users such as mouse clicks or scrolling if you are conducting website
          usability testing for example. Session recordings are a superb way of spotting major
          problems in your product and its functionalities. If you want to test the potential of
          user session recordings, you can try out{' '}
          <Link href={paragraph.link} target="_blank" rel="noopener noreferrer">
            LiveSession
          </Link>{' '}
          for free.
        </li>
        <li>
          <strong>Benchmark tests</strong>: Benchmark studies allow you to test how a website, app,
          or product progresses over time and where it stands in comparison to competitors, earlier
          versions, or industry benchmarks.{' '}
        </li>
        <li>
          <strong>Accessibility tests</strong>: These tests allow you to check whether your software
          or product can be comfortably used by people with disabilities, like deafness, physical
          limitations, and even color blindness and age.
        </li>
      </ul>
      <h3>Stage 4 – Listen</h3>
      <p>
        In the fourth stage, among others, user experience researchers can apply the following
        methods:
      </p>
      <ul>
        <li>
          <strong>Surveys & questionnaires</strong>: This UX research method can be carried out face
          to face, over the phone, computer, or through a website. The questionnaire uses both open
          and closed-ended questions to collect data. It is very useful as it ensures that both
          quantitative and qualitative is obtained.{' '}
        </li>
        <li>
          <strong>Bug reports’ analysis</strong>: When you build new products, bugs are bound to
          happen. Some are relatively harmless and do not deter users from performing important
          tasks, but some can cause major interruptions and distractions. Before releasing your
          product, it is vital to eliminate those bugs. One thing that helps immensely in your{' '}
          <Link href="/user-experience/" target="_blank" rel="noopener noreferrer">
            user experience
          </Link>{' '}
          research efforts is creating and analyzing bug reports.{' '}
        </li>
        <li>
          <strong>Analyzing FAQs</strong>: Frequently asked questions from users can guide you as to
          critical improvement areas. Carefully assessing the frequently asked user questions is
          imperative when trying to improve your product and its UX design.
        </li>
        <li>
          <strong>Analyzing search queries on-site</strong>: Search logs and queries that can be
          found in{' '}
          <Link
            href="https://search.google.com/search-console/about"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            Google Search Console
          </Link>{' '}
          are often overlooked, but they also contain important information. As an example, your
          on-site user queries can easily convey what the people are searching for, what do they
          call it, and how they look for it.{' '}
        </li>
      </ul>
      <p>
        <strong>When are these methods used?</strong> These methods are used for improving UX and
        adjusting the product to users’ and the market’s changing needs over time.
      </p>
      <h2>Summary</h2>
      <p>
        UX research is an indispensable element of any UX process. It is applied across all stages
        of product development – both in the early ideation and design phases, as well as once it’s
        up and running. Conducting effective UX research can be performed across a variety of
        methods, and helps you maximize the profits and returns for your products and services.
        Last, but not least, it allows you to validate your hypothesis and analyze your product
        against similar solutions and guides you in understanding the end user&apos;s expectations,
        goals, and challenges. Follow the methods we discussed in this post, and you’ll be sure to
        ace your user experience research.
      </p>
      <p>Good luck!</p>
    </>
  );
};

export default withUsabilityTestingTemplate(UXResearch);
